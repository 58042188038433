import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import {Navbar} from './components';
import React from 'react';
import { Helmet } from 'react-helmet';
import GlobalStyles from './globalStyles';
const homeObjTwo = {
  elem1: 'Hello', 
  elem2: 'Bye'
}
function App() {
  return (
    <Router>
      <Helmet>
          <title>Complices GB</title>
        </Helmet>
      <GlobalStyles {...homeObjTwo} />
      <Navbar />
    </Router>
  );
}

export default App;
