import React, {useState, useEffect , useRef} from 'react'
import { FaBars, FaTimes, FaWindows } from 'react-icons/fa';
import LogoImage from '../../images/logobarblanco.jpg';
import CentroImage from '../../images/imagenfondo.jpg';
import   { useWindowScroll }  from 'react-use';
import '../../App.css';
import { InstagramOutlined, GoogleOutlined } from '@ant-design/icons';

import {
    Nav, 
    NavbarContainer, 
    NavLogo,
    NavIcon, 
    MobileIcon,
    NavMenu,
    NavItem,
    NavLinks,
    NavItemBtn,
    NavBtnLink
    } from './Navbar.elements';

import { Button } from '../../globalStyles';
import {IconContext} from 'react-icons/lib';
import { ImageObj, homeObjOne, homeObjTwo, homeObjThree, homeObjFour } from '../../pages/HomePage/Data'
import { ImageSection, InfoSection, InfoSection2, InfoSection3, InfoSection4 } from '../../components'



const Navbar = ({elem1, elem2}) => {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);

    const InicioRef = React.createRef();
    const CartaRef = React.createRef();
    const CartaPostresRef = React.createRef();
    const CartaVinosRef = React.createRef();

    const scrollToInicio = () => {
        setClick(false);
        InicioRef.current.scrollIntoView({behavior: "smooth"});
    }
    const scrollToCarta = () => {
        setClick(false);
        CartaRef.current.scrollIntoView({behavior: "smooth"});
    }
    const scrollToCartaPostres = () => {
        setClick(false);
        CartaPostresRef.current.scrollIntoView({behavior: "smooth"});
    }
    ;
    const scrollToVino = () => {
        setClick(false);
        CartaVinosRef.current.scrollIntoView({behavior: "smooth"})
    };
    
    const handleClick = () => setClick(!click);
    const CloseWindow = () => setClick(false);
    const showButton = () => {

        if(window.innerWidth <= 960){
            setButton(false);
        }else{
            setButton(true);
        }
    };
    window.addEventListener('resize', showButton);

    return (
        <>
            <IconContext.Provider value={{ color : '#fff ' }}>
                <Nav>
                    
                    <NavbarContainer>
                        
                        <NavLogo to="/">
                            {/*}
                            <NavIcon />
                            */}
                            <img src={LogoImage} onClick={scrollToCarta} alt="logo" style={{height : "45px", width : "150px"}}/>
                        
                        </NavLogo>
                        <MobileIcon onClick={handleClick}>
                            {click ? <FaTimes /> : <FaBars />}
                        </MobileIcon>
                        
                        <NavMenu click={click}>
                            <NavItem>
                                <NavLinks onClick={scrollToInicio}>
                                    Inicio
                                </NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks onClick={scrollToCarta}>Carta</NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks onClick={scrollToCartaPostres}>Carta de postres</NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks onClick={scrollToVino}>Carta de vinos</NavLinks>
                            </NavItem>
                        </NavMenu>

                    </NavbarContainer>
                
                </Nav>
            </IconContext.Provider>
            <div ref={InicioRef}/>
            <ImageSection {...ImageObj}/>
            <div ref={CartaRef}/>
            <InfoSection {...homeObjOne}/>
            <div ref={CartaPostresRef}/>
            <InfoSection2 {...homeObjTwo} />
            <div ref={CartaVinosRef}/>
            <InfoSection3 {...homeObjThree} />
            <InfoSection4 {...homeObjFour} />
            <footer>

                            <div class="footer-limiter">

                                <div class="footer-right">

                                    <a href="https://www.instagram.com/complices_gastrobar/"><InstagramOutlined class="fa fa-facebook"/></a>
                                    <a href="https://www.google.com/search?q=complices+gastrobar&client=avast-a-2&ei=dqsGYaiOBcGdjLsPqueE8A0&oq=complices+gastrobar&gs_lcp=Cgdnd3Mtd2l6EAMyDgguEIAEEMcBEK8BEJMCMgQIABAeOggIABCwAxDNAjoFCAAQzQI6BggAEAcQHkoECEEYAVDiqwRY2soEYJzQBGgBcAB4AIAB7wGIAY8LkgEFMC42LjKYAQCgAQHIAQHAAQE&sclient=gws-wiz&ved=0ahUKEwiooaqugJDyAhXBDmMBHaozAd4Q4dUDCA4&uact=5"><GoogleOutlined class="fa fa-facebook"/></a>
                                    
                                </div>

                                <div class="footer-left">

                                    <p class="footer-links">Plaça de la Vila, 3 | 46701 Gandia</p>

                                    <p>telefono de reserva: 658 43 64 43</p>
                                </div>

                            </div>

            </footer>
        </>
    )
}

export default Navbar;
