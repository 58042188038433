import Carta1 from '../../images/logobarblanco.jpg'

export const homeObjOne = {

    
    primary: true, 
    lightBg: false,
    imgStart: false, 
    lightTopLine: true, 
    topLine: 'Carta Comida', 
    lightText: true, 
    headLine: 'Gastrobar Complices', 
    lightTextDesc: true, 
    description: 'GastroBar con selecta carta de comida',
    start: true,
    alt: 'Carta'

}
export const homeObjTwo = {

    
    primary: true, 
    lightBg: true,
    imgStart: true, 
    lightTopLine: false, 
    topLine: 'Carta de Postres', 
    lightText: false, 
    headLine: 'Gastrobar Complices', 
    lightTextDesc: false, 
    description: '',
    start: false,
    img: require('../../images/logo.svg'),
    alt: 'logo'

}
export const homeObjThree = {

    
    primary: true, 
    lightBg: false,
    imgStart: false, 
    lightTopLine: true, 
    topLine: 'Carta de vinos', 
    lightText: true, 
    headLine: 'Gastrobar Complices', 
    lightTextDesc: true, 
    description: 'GastroBar con selecta carta de vinos', 
    buttonLabel: 'Get Started',
    start: true,
    img: require('../../images/logo.svg'),
    alt: 'logo'

}
export const homeObjFour = {

    
    primary: true, 
    lightBg: true,
    imgStart: true, 
    lightTopLine: false, 
    topLine: 'Carta vinos', 
    lightText: false, 
    headLine: 'Gastrobar Complices', 
    lightTextDesc: false, 
    description: 'GastroBar con selecta carta de vinos', 
    buttonLabel: 'Get Started',
    start: true,
    img: require('../../images/logo.svg'),
    alt: 'logo'

}

export const ImageObj = {

    
    primary: true, 
    lightBg: false,
    imgStart: false, 
    lightTopLine: true, 
    topLine: 'Carta Comida', 
    lightText: true, 
    headLine: 'Gastrobar Complices', 
    lightTextDesc: true, 
    description: 'GastroBar con selecta carta de comida',
    start: false,
    alt: 'Carta Parte 1'

}